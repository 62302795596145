import React, { useState } from "react";
import Container from "../components/container";

export default function Regalo() {
  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          ¡Enhorabuena, lo habéis conseguido!
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/premio.jpg"
          title="Regalo"
          alt="Regalo"
        />

        <button
          type="submit"
          className=" mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <a href={"/premio.zip"}>Descargar el premio</a>
        </button>
      </section>
    </Container>
  );
}
